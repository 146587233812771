import React from 'react';

import CharlesMeta from 'components/common/meta/CharlesMeta';
import Start from 'components/Start/StartGeneral';

const StartPage = () => (
  <>
    <CharlesMeta
      data={{
        title:
                    'Consultation en ligne contre les problèmes de santé sexuelle féminine.',
        description:
                    'Commencez maintenant votre téléconsultation avec une sexologue.',
      }}
    />
    <Start />
  </>
);

export default StartPage;
