import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Layout } from 'components/common';

import Logo from 'images/mia-logo-blue.svg';
import Support from 'images/icons/start/support.svg';
import France from 'images/icons/start/France.svg';
import HADS from 'images/icons/start/HADS.svg';
import Mastercard from 'images/icons/start/Mastercard.svg';
import Visa from 'images/icons/start/Visa.svg';
import SSL from 'images/icons/start/SSL.svg';
import Mangopay from 'images/icons/start/Mangopay.svg';

import pathologies from 'content/pathologies.json';

import StaticStepper from '../StaticStepper';
import StartFooterRow from '../StartFooterRow';

import bullets from '../data';
import Bullet from '../Bullet';

const tops = pathologies.filter((pathology) => pathology.importance === 'top');

const StartLink = ({ pathology: { slug, title, subtitle } }) => (
  <Link to={`/${slug}/`} className="btn-orange start-cta">
    <span className="start-span-cta">
      {title}
    </span>
  </Link>
);

const IndexPage = () => (
  <>
    <Helmet>
      <html className="html-class-small-font-page" />
    </Helmet>
    <Layout
      showBars={false}
      showNewsletterBeforeFooter={false}
    >
      <div className="text-center text-md-left start-page lc-light-grey vh-100 w-100">
        <Container className="top-container d-flex justify-content-between">
          <Logo className="start-logo" />
          <a className="phone-charles-block d-flex no-underline-hover" href="tel:0186651733">
            <div className="phone-div d-flex align-items-center">
              <Support height={30} />
              <span className="hide-number ml-2">01 86 65 18 91</span>
              <France height={30} className="logo-france" />
            </div>
          </a>
        </Container>
        <Container>
          <StaticStepper className="" />
        </Container>
        <Container className="pb-3 pb-sm-5 main-container">
          <Row className="main-start-panel">
            <Col xs={12} xl={{ span: 7 }} className="lc-white left-col">
              <div className="d-flex flex-column left-div">
                <h1 className="start-title">
                  Mia.co vous accompagne !
                </h1>
                <p className="start-text">
                  Choisissez votre motif de consultation pour que nous puissions adapter le
                  questionnaire et vous mettre en relation avec l’une de nos sexologues.
                </p>
                <div className="top-wrapper">
                  {tops.map((top, index) => (
                    <div key={index} className="top-cards">
                      <StartLink
                        pathology={top}
                      />
                      <span className="start-general-pb-description">
                        {top.problemDescription}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="secure-logo-container text-center">
                  <span className="secure-by">Sécurisé par</span>
                  <div className="d-flex justify-content-between align-items-center secure-logos">
                    <Mastercard height={30} className="mr-1 mr-sm-0" />
                    <SSL height={30} />
                    <Visa height={30} />
                    <HADS height={80} />
                    <Mangopay height={30} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} xl={{ span: 5 }} className="background-color-pale-yellow right-col">
              <div className="text-left right-div">
                <div className="d-flex flex-row justify-content-between flex-wrap bullet-wrap">
                  {bullets.map((bullet, index) => (
                    <Bullet data={bullet} key={`bullet_${index}`} />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <StartFooterRow />
        </Container>
      </div>
    </Layout>
  </>
);

export default IndexPage;
